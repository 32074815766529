import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from 'html-react-parser';
import { useLocation, useNavigate } from "react-router-dom";
import { bindPaymentOrderNo, bindShowHideForm } from "../store/donationSlice";
import "../scss/froala_style.min.css";
import { formatSuccessMessage } from "../utility/util";

const Success = (props) => {

  const formSettings = useSelector((state) => state?.donation?.formSettings || {});
  const paymentOrderNo = useSelector((state) => state?.donation?.paymentOrderNo || "");
  const streamoptions = useSelector((state) => state?.donation?.streamOptions || {});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { udata, totalAmount } = location.state || '';


 useEffect(()=>{
  window.scrollTo(0, 0);
 
 },[formSettings.ThankyouMessage])


  const donateAgain = () => {
    dispatch(bindPaymentOrderNo(""))
    dispatch(bindShowHideForm((streamoptions.SPS === 1) ? false: true));
    navigate("/loadform",{ state: { showDForm: (streamoptions.SPS === 1) ? false: true } });
  };

  return (
    <>
     
     <svg width="30" height="30" style={{width: '30px', height: '30px', margin:'2rem auto 0', display:'block'}} xmlns="https://www.w3.org/2000/svg" viewBox="0 0 16 16">
<path d="M4.7 6.5L3.6 7.6l3.6 3.6 8-8 -1.1-1.1L7.2 9 4.7 6.5zM14.4 8c0 3.5-2.9 6.4-6.4 6.4 -3.5 0-6.4-2.9-6.4-6.4 0-3.5 2.9-6.4 6.4-6.4 0.6 0 1.2 0.1 1.8 0.2L11 0.6C10.1 0.2 9 0 8 0 3.6 0 0 3.6 0 8c0 4.4 3.6 8 8 8 4.4 0 8-3.6 8-8H14.4zM14.4 8" 
fill={formSettings.ThankYouMessageSettings && formSettings.ThankYouMessageSettings.iconColor ? formSettings.ThankYouMessageSettings.iconColor: `#6DA77A`}></path>
</svg>
 
    {formSettings.ThankyouMessage ? (
      <div id="thankyou-message" style={{fontSize:'13px'}} dangerouslySetInnerHTML={{ __html: parse(formatSuccessMessage(formSettings.ThankyouMessage, udata, totalAmount, paymentOrderNo, formSettings.Currency)) }}>
      
      </div>
    ):null}
     
      {props.donateAgain ? (
        <div className="form_input_wrapper d-flex justify-content-between mt_2">
            <button
            className="full_button tabindex_btn next"
            type="button"
            onClick={donateAgain}
            data-index="0"
            style={{margin:'0 0.5rem'}}
            >
            {" "}
            &#60; {(streamoptions.SPS === 1) ?`View Stream`:`Back` }{" "}
            </button>
         </div>
      ): null}

     
    </>
  );
};

export default Success;
