//Local start here

export  const AUTH_API = "https://o1gxr2k611.execute-api.us-east-1.amazonaws.com/zoomAPIs";
export  const ZOOM_USER_API = "https://agpihkzh69.execute-api.us-east-1.amazonaws.com/zoomdata";
export  const ZOOM_SDB_API = "https://wikhlo9oc2.execute-api.us-east-1.amazonaws.com/cnpzoomapp";
export  const GET_FORM_API = "https://czgizz482a.execute-api.us-east-1.amazonaws.com/getformdetails";
export  const GET_FORM_BY_PARAMS_API = "https://xi1cvoj4f5.execute-api.us-east-1.amazonaws.com/zoom";
export  const SUBMIT_API = "https://utqnfro7z9.execute-api.us-east-1.amazonaws.com";
export  const FORM_SETTINGS_API = "https://rdiqsd9jy8.execute-api.us-east-1.amazonaws.com/zoom";
export const SUBMIT_SOAP_API="https://hpbh657yp3.execute-api.us-east-1.amazonaws.com/submit";

// Local ends


export  const SALT_KEY = "gatheringjar$@0047";
export const CSS_PATH = "https://aws.assets.clickandpledge.com/zoomapp";






